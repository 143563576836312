<template>
    <div style="width:100vw;margin:-12px;">
        <!-- app bar -->
        <top-nav title="Cupones"></top-nav>

        <div v-if="coupons != null">
            <!-- content -->
            <v-list two-line>
                <template v-for="(coupon, ix) in coupons">
                    <v-list-item :key="coupon.id" :to="'/secure/coupon/' + coupon.id">
                        <v-list-item-content>
                            <v-list-item-title class="text-left">{{ coupon.code }}</v-list-item-title>
                            <v-list-item-subtitle class="text-left">
                                {{ coupon.description }}
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-avatar size="16" :color="coupon.state == 'Draft' ? 'warning' : 'success'"></v-avatar>
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider inset v-if="ix < coupons.length - 1" :key="coupon.id + '-divider'"></v-divider>
                </template>
            </v-list>

            <v-btn color="primary" dark fixed bottom right fab class="addBtn" to="/secure/coupon">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>

        <v-skeleton-loader class="mx-auto" type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line" v-else></v-skeleton-loader>
    </div>
</template>

<script>
import TopNav from '../../components/admin/TopNav.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters({
            user                : 'auth/user',
            coupons             : 'couponsInfo/coupons'
        }),
    },
    async mounted() {
        await this.loadCoupons();

        console.log( this.coupons );
    },
    methods: {
        ...mapActions({
            getCouponsData: 'couponsInfo/getCouponsData',
        }),
        async loadCoupons() {
            await this.getCouponsData();
        },
    },
    components: {
        TopNav
    }
}
</script>

<style lang="scss" scoped>
.addBtn {
    bottom: 15px !important;
}
</style>